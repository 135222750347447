import React from 'react';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { green } from '@mui/material/colors';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { LinearProgress } from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;
function LeaveLetterSuccess(letterData) {

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);



  const handleDownload = async () => {
    setIsDownloading(true);
    const url = API_URL+`/leave-letters/download/${letterData.letterData.id}`;
    const apiKey = process.env.REACT_APP_API_KEY;// Replace with your real key

    try {
      const response = await fetch(url, {
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        }
      }); 

      const contentLength = +response.headers.get('content-length');
      let received = 0;

      const reader = response.body.getReader(); // Create reader once
      const chunks = []; 
      while (true) { // Using a loop to read iteratively
        
        const { done, value } = await reader.read(); 


        if (done) {
          setIsDownloading(false);
          break; // Exit the loop
        } 
        chunks.push(value);
        received += value.length;
        setDownloadProgress(contentLength ? Math.round((received / contentLength) * 100) : 0);
      }
      const arrayBuffer = new Uint8Array(received).buffer; // Combine stored chunks
      const blob = new Blob(chunks);
      
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = `leave_letter_${letterData.letterData.id}.jpg`; 
          link.click();

          URL.revokeObjectURL(link.href); 
      
      
    } catch (error) {
      setIsDownloading(false);
      console.error('Download error:', error);
    } finally {
      setIsDownloading(false);
    }


    
// fetch(url, {
//   headers: {
//     'X-API-Key': apiKey,
//   }
// })  
//   .then(response => {
//   const contentLength = +response.headers.get('content-length'); // Get file size

//   let received = 0;
//   response.body.getReader().read().then( ({done, value}) => {
//     if (done) {
//       // Download complete
//       setIsDownloading(false);
//     } else {
//       received += value.length;
//       setDownloadProgress(contentLength ? Math.round((received / contentLength) * 100) : 0);
//       response.body.getReader().read();
//     }
//   });
// })
      // .then(response => response.blob())
      // .then(blob => {
      //   // Create a link and simulate a click to download
      //   const link = document.createElement('a');
      //   link.href = URL.createObjectURL(blob);
      //   link.download = `leave_letter_${letterData.id}.jpg`; // Or any desired filename
      //   link.click();

      //   // Cleanup
      //   URL.revokeObjectURL(link.href);
      // })
//       .catch(error => console.error('Download error:', error)); 
  };




  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        justifyContent: 'center',
        height: '85vh' // Center vertically within screen
      }} 
    >
      <Paper elevation={3} sx={{ padding: '20px',margin:'10px' }}>
        <CheckCircleIcon 
          sx={{ fontSize: 80, color: green[500] }} 
        />
        <Typography variant="h5" gutterBottom>
          Leave Letter Submitted Successfully
        </Typography>
        <Typography variant="caption" gutterBottom>
          Kindly please contact G-TEC Haripad for further details
        </Typography>
        <Box sx={{marginTop:'10px'}}>
        <Button variant="contained" onClick={handleDownload} startIcon={<FileDownloadIcon />}>
        Download Letter
      </Button>
        <Paper sx={{marginTop:'10px'}}>
           
    {isDownloading && <LinearProgress color='success' variant="determinate" value={downloadProgress} />} 

        </Paper>
        </Box>
        
      </Paper>
    </Box>
  );
}

export default LeaveLetterSuccess;
