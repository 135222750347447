import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const API_URL = process.env.REACT_APP_API_URL;

const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-row:nth-child(even)': {
                        backgroundColor: 'whitesmoke',
                    }, '& .MuiDataGrid-row:nth-child(odd)': {
                        backgroundColor: '#e0e0e0'
                    }
                },
                columnHeaderTitle: {
                    fontWeight: 'bold'
                }
            },
        },
    },
});

async function deleteLeaveLetter(id) {
    try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiUrl = API_URL+`/leave-letters/${id}`;

        const response = await fetch(apiUrl, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            }
        });

        if (!response.ok) {
            throw new Error('Failed to delete leave letter');
        }

        // Return true if deletion is successful
        return true;

    } catch (error) {
        console.error('Error deleting leave letter:', error);
        // Return false if deletion fails
        return false;
    }
}

async function changeApprovalStatus(id, newStatus) {
    try {
        const apiKey = process.env.REACT_APP_API_KEY;
        const apiUrl = API_URL+`/leave-letters/${id}/approve`;

        const response = await fetch(apiUrl, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: JSON.stringify({ 'approved': newStatus }),
        });

        if (!response.ok) {
            throw new Error('Failed to change approval status');
        }

        // Return true if status change is successful
        return true;

    } catch (error) {
        console.error('Error changing approval status:', error);
        // Return false if status change fails
        return false;
    }
}







function formatDate(date) {
    // If it's a string, assume an input format and parse it into a Date object
    if (typeof date === 'string') {
        // Adjust the input format if needed (e.g., "yyyy-MM-dd")
        date = new Date(date);
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
}

function getExpiryStatus(toDate) {
    const today = new Date();
    const diffTime = Math.abs(toDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (toDate < today) {
        return 'expired';
    } else if (diffDays <= 7) {
        return 'going to expire';
    } else {
        return 'active';
    }
}







function LeaveLetterDataGrid(prop) {
    const [isApproved, setIsApproved] = useState(false);
    const [rowsA, setRowsA] = useState([]);
    const [rowsB, setRowsB] = useState([]);
    const [currentRow, setcurrentRow] = useState([]);
    const [isDwonload, setIsDownload] = useState(false);
    const [filterStatus, setFilterStatus] = useState('all'); // Initial state is 'all'
    const [expiryFilter, setExpiryFilter] = useState('all');
    const [rejoinedFilter, setRejoinedFilter] = useState('all');
    useEffect(() => {

        //
        setRowsB(prop.rows);

        // ... (other logic that might update rowsA later)
    }, [prop.rows]);




    // useEffect(() => {
    //     // This will run initially and when the prop.rows updates.
    //     setRowsA(prop.rows);
    //     const filteredRows = prop.rows.filter(row => {
    //         if (filterStatus === 'all') {
    //             return true;
    //         } else {
    //             return row.approved === filterStatus;
    //         }
    //     });
    //     setRowsA(filteredRows);
    // }, [prop.rows, filterStatus]);

    useEffect(() => {
        const filteredRows = rowsB.filter(row => {
            const matchesStatus = filterStatus === 'all' || row.approved === filterStatus;
            const matchesExpiry = expiryFilter === 'all' || getExpiryStatus(new Date(row.todate)) === expiryFilter;
            const matchesRejoined = rejoinedFilter === 'all' || row.rejoined === rejoinedFilter;

            return matchesStatus && matchesExpiry && matchesRejoined;
        });

        setRowsA(filteredRows);

    }, [rowsB, filterStatus, expiryFilter, rejoinedFilter]);


    async function handleRejoinedChange(row, newValue) {
        try {
            const apiKey = process.env.REACT_APP_API_KEY;
            const apiUrl =API_URL+ `/leave-letters/${row.id}/rejoined`;

            const response = await fetch(apiUrl, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ rejoined: newValue }),
            });

            if (!response.ok) {
                throw new Error('Failed to update rejoined status');
            }

            // Update the rejoined status in the row object (optimistic update)
            const updatedRows = rowsB.map(existingRow =>

                existingRow.id === row.id ? { ...existingRow, rejoined: newValue } : existingRow
            );

            setRowsB(updatedRows);

        } catch (error) {
            console.error('Error updating rejoined status:', error);
            // Handle the error appropriately (e.g., show error message)
        }
    }



    // useEffect(() => {
    //     const filteredRows = prop.rows.filter(row => {
    //         const matchesStatus = filterStatus === 'all' || row.approved === filterStatus;
    //         const matchesExpiry = expiryFilter === 'all' || getExpiryStatus(new Date(row.todate)) === expiryFilter;

    //         return matchesStatus && matchesExpiry;
    //     });
    //     setRowsA(filteredRows);
    // }, [prop.rows, filterStatus, expiryFilter]);

    async function handleCellEdit(params) {
        if (params.remarks == undefined) {
            return;
        }
        const updatedRemarks = params.remarks;

        const rowId = params.id;
        
        // Update the state immediately for optimistic UI updates
        // setRowsB(rowsB => rowsB.map(row => {
        //     if (row.id === rowId && row.remarks != "") {
        //         return { ...row, remarks: updatedRemarks };
        //     } else {
        //         return row;
        //     }
        // }));

        // Make API call to save the change
        try {
            const apiKey = process.env.REACT_APP_API_KEY;
            const apiUrl = API_URL+`/leave-letters/${rowId}/remarks`;

            const response = await fetch(apiUrl, {
                method: 'PUT',
                body: JSON.stringify({ remarks: updatedRemarks }),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            // Success! You might do nothing, or update state if needed

        } catch (error) {
            console.error("Error saving remarks:", error);
            // Handle the error - e.g., display an error message to the user
            // You might also revert the remarks in your leaveData state
        }
    }

    async function downloadLetter(row) {
        const url = API_URL+`/leave-letters/download/${row.id}`;
        const apiKey = process.env.REACT_APP_API_KEY;// Replace with your real key

        try {
            const response = await fetch(url, {
                credentials: 'include',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
            const contentLength = +response.headers.get('content-length');
            let received = 0;

            const reader = response.body.getReader(); // Create reader once
            const chunks = [];
            while (true) { // Using a loop to read iteratively

                const { done, value } = await reader.read();


                if (done) {
                    setIsDownload(false);
                    break; // Exit the loop
                }
                chunks.push(value);
                received += value.length;
                //setDownloadProgress(contentLength ? Math.round((received / contentLength) * 100) : 0);
            }
            const arrayBuffer = new Uint8Array(received).buffer; // Combine stored chunks
            const blob = new Blob(chunks);

            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `leave_letter_${row.id}.jpg`;
            link.click();

            URL.revokeObjectURL(link.href);


        } catch (error) {
            setIsDownload(false);
            console.error('Download error:', error);
        } finally {
            setIsDownload(false);
        }

    }
    const handleApprove = async (row) => {
        // Implement your approval logic here (e.g., update row.Status)
        const success = await changeApprovalStatus(row.id, 'approved');
        if (success) {
            // Update the status in the row object
            row.approved = 'approved';
            setRowsA(rowsA.filter(existingRow => existingRow.id !== row.id));
            // Assuming setIsApproved is used to trigger re-render
        }
    };
    const handleProcessRowUpdateError = async () => {

    }
    const handleDelete = async (row) => {
        const success = await deleteLeaveLetter(row.id);
        if (success) {
            // Update the status in the row object
            setRowsA(rowsA.filter(existingRow => existingRow.id !== row.id));
            // Assuming setIsApproved is used to trigger re-render
        }
    };

    const handleDownload = async (row) => {
        setcurrentRow(row);
        setIsDownload(true);
        
        const success = await downloadLetter(row);
        if (success) {
            
        }
    }

    const handleLogout = async () => {
       
        try {
            //return;
            //const API_URL = process.env.REACT_APP_API_URL;
            const apiUrl = API_URL + `/logout`;
        
            const response = await fetch(apiUrl, {
              credentials: 'include',
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
              }
            });
        
            
            if (!response.ok) {
              
              throw new Error('API request failed');
            }
            
            prop.onSetLogout();
            
        
            const data = await response.json();
            return data; // Return the data for the component to use
        
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle the error, e.g., return an empty array or set an error state
            return []; 
          }
    }


    const columnDefs = [
        { field: "id", headerName: "ID", width: 50, hide: true }, // hide if you want
        { field: "regno", headerName: "Reg. No.", width: 80, },
        { field: "sname", headerName: "Student Name", width: 200 },
        { field: "course", headerName: "Course", width: 160 },
        { field: "section", headerName: "Department", width: 80 },

        {
            field: "fromdate",
            headerName: "From Date",
            width: 100,
            valueFormatter: (params) => formatDate(params.value)
        },
        {
            field: "todate",
            headerName: "To Date",
            width: 100,
            valueFormatter: (params) => formatDate(params.value)
        },
        { field: "totaldays", headerName: "Total Days", width: 20 },
        { field: "reason", headerName: "Reason", width: 300 },
        // ... other fields 
        // { field: "approved", headerName: "Status", width: 100 },


        {
            field: "approveButton",
            headerName: "Approve",
            width: 100,
            renderCell: (params) => (

                <IconButton color={params.row.approved === 'pending' ? 'warning' : 'success'} onClick={() => handleApprove(params.row)}>
                    <VerifiedUserIcon />
                </IconButton>
            ),
        },

        {
            field: "rejoined",
            headerName: "Rejoined",
            width: 150,
            renderCell: (params) => {
                const backgroundColor = {
                    not_rejoined: 'yellow',
                    rejoined: 'green',
                    dropout: 'red'
                }[params.row.rejoined || ''] || 'white'; // Default to white if no match

                return (
                    <Select
                        value={params.row.rejoined || ''}
                        onChange={(event) => handleRejoinedChange(params.row, event.target.value)}
                        size="small"
                        style={{ backgroundColor }} // Apply the computed background color
                    >
                        <MenuItem value="not_rejoined">Not Rejoined</MenuItem>
                        <MenuItem value="rejoined">Rejoined</MenuItem>
                        <MenuItem value="dropout">Dropout</MenuItem>
                    </Select>
                );
            },
        },


        {
            field: "DeleteButton",
            headerName: "Delete",
            width: 60,
            renderCell: (params) => (
                <IconButton color='error' onClick={() => handleDelete(params.row)}><DeleteIcon /></IconButton>
            ),
        },
        {
            field: "DownloadLetter",
            headerName: "Download",
            width: 60,
            renderCell: (params) => (
                <IconButton color='success' onClick={() => handleDownload(params.row)}><DownloadIcon /></IconButton>
            ),
        },
        { field: "remarks", headerName: "Remarks", width: 200, editable: true },
    ];




    return (
        <Box sx={{ height: '80vh', width: '95%', marginTop: '5%' }}>
            <Box sx={{ mb: 2 }}> {/* Margin bottom for spacing */}
                <Select
                    sx={{ width: '200px' }}
                    value={filterStatus}
                    onChange={(event) => setFilterStatus(event.target.value)}
                    label="Filter by Status"
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="approved">Approved</MenuItem>
                </Select>

                <Select

                    sx={{ ml: 2, width: '200px' }}
                    value={expiryFilter}
                    onChange={(event) => setExpiryFilter(event.target.value)}
                    label="Filter by Expiry"
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="expired">Expired</MenuItem>
                    <MenuItem value="going to expire">Going to Expire</MenuItem>
                </Select>

                <Select
                    sx={{ ml: 2, width: '200px' }}
                    value={rejoinedFilter}
                    onChange={(event) => setRejoinedFilter(event.target.value)}
                    label="Filter by Rejoined"
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="not_rejoined">Not Rejoined</MenuItem>
                    <MenuItem value="rejoined">Rejoined</MenuItem>
                    <MenuItem value="dropout">Dropout</MenuItem>
                </Select>

                <Button
                    
                    variant="contained"
                    color="error" // Adjust color for logout if desired
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            </Box>
            <ThemeProvider theme={theme}>
                <DataGridPro
                    sx={{ fontSize: '14px' }}
                    onCellEditStop={handleCellEdit}
                    rows={rowsA}
                    columns={columnDefs}
                    processRowUpdate={(updatedRow, rowsA) =>
                        handleCellEdit(updatedRow)
                    }
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    pageSize={5} // Adjust as needed
                    rowsPerPageOptions={[5, 10, 20]} // Options if desired
                    pagination={true}
                />

            </ThemeProvider>
        </Box>
    );
}

export default LeaveLetterDataGrid;