import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import logo from '../../images/logo.png'; // Import your logo

function NavBar() {
  return (
    <AppBar position="fixed"> 
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center' }}> 
          <img src={logo} alt="G-TEC Logo" style={{ height: '30px', marginRight: '10px' }} />
          <Typography variant="h6">G-TEC Haripad</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;
