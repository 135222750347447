import React from 'react';
import { Card, CardContent, Typography, Grid, Button } from '@mui/material';
import CourseMap from './CourseMap.json';
function getShortForm(courseName) {
    // **We'll assume course mappings are stored in a separate data file. 
    // You'll need to replace 'course_mappings.json' with the path to your data file**
    
        // Logic to find the short form (same as before)
        let shortForm = CourseMap[courseName];
  
        if (!shortForm) {
          // Case-insensitive lookup (same as before) ... 
        }
  
        // If short form is found, return it  
        if (shortForm) {
          return shortForm;
        } else {
          // Handle cases where there's no match (same options as before) 
        }
    

  }




function ShowStudentDetails({studentData, onConfirm, onCancel }) {
    const { RegNo, SName, Course } = studentData;
    
  return (

    
    <Card sx={{ maxWidth: 400, margin: '20px 10px',background:'rgba(255,255,255,0.5)' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{}}>
            <Typography variant="subtitle1" sx={{fontWeight:'bold',textAlign:'left'}}>Register Number</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="left">
              : {RegNo}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{fontWeight:'bold',textAlign:'left'}}>Name</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="left">
              : {SName}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1" sx={{fontWeight:'bold',textAlign:'left'}}>Course</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" align="left">
              : {getShortForm(Course)}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="outlined" onClick={onCancel} sx={{ marginRight: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onConfirm}>
              Next
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ShowStudentDetails;
