import { Box } from '@mui/material';
import ResponsiveAppBar from './ResponsiveAppBar';
import ShowStudentDetails from './ShowStudentDetails';
import LeaveLetterForm from './LeaveLetterForm';
import LeaveLetterSuccess from './LeaveLetterSuccess';
import RegisterInput from './RegisterInput';
import React, { useState } from 'react';

function LeaveLetterIndex(){
  const [currentStep, setCurrentStep] = useState(1);
  const [studentData, setStudentData] = useState({
    RegNo: '',
    SName: '',
    Course: '',
    // ... other required properties
  });

  const [letterData,setLetterData]=useState({});
  return(
    <Box sx={{boxSizing:'border-box'}}>
      <ResponsiveAppBar/>
      <Box className='App bg-gra-03' sx={{ height:'100vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        {currentStep === 1 && <RegisterInput onNextStep={(data) => {setCurrentStep(2); setStudentData(data) }} />}
        {currentStep === 2 && <ShowStudentDetails studentData={studentData} onConfirm={() => setCurrentStep(3)} onCancel={() => setCurrentStep(1)} />}
        {currentStep === 3 && <LeaveLetterForm studentData={studentData} onCancel={() => setCurrentStep(1)} onSuccess={(ldata) => {setCurrentStep(4);setLetterData(ldata)}} />}
        {currentStep === 4 && <LeaveLetterSuccess letterData={letterData}/>}
      </Box>
    </Box>
  );
}

export default LeaveLetterIndex;