import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { useEffect } from 'react';
import { parse, format } from 'date-fns';
import CourseMap from './CourseMap.json';
import { wait } from '@testing-library/user-event/dist/utils';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers/DatePicker'; // etc.
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green,red } from '@mui/material/colors';


const API_URL = process.env.REACT_APP_API_URL;
function calculateTotalDays(fromDate, toDate) {
  // Assume fromDate and toDate are in the format 'YYYY-MM-DD'
  const date1 = new Date(fromDate);
  const date2 = new Date(toDate);

  // Calculate the difference in milliseconds
  const differenceInTime = date2.getTime() - date1.getTime();

  // Convert milliseconds to days
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);

  return differenceInDays;
}

function getShortForm(courseName) {
  // **We'll assume course mappings are stored in a separate data file. 
  // You'll need to replace 'course_mappings.json' with the path to your data file**
  
      // Logic to find the short form (same as before)
      let shortForm = CourseMap[courseName];

      if (!shortForm) {
        // Case-insensitive lookup (same as before) ... 
      }

      // If short form is found, return it  
      if (shortForm) {
        return shortForm;
      } else {
        // Handle cases where there's no match (same options as before) 
      }
  

}


function LeaveLetterForm({ studentData, onCancel, onSuccess }) {
  const apiKey = process.env.REACT_APP_API_KEY;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid rgba(0, 0, 0, 0.57)',
    boxShadow: 24,
    p: 1,
  };


  
  // const csrfToken='';
  // useEffect(() => {
  //   const fetchCsrfToken = async () => {
  //     const response = await fetch('https://devapi.haripadg.tech/csrf-token',{headers: {
  //       'X-API-Key': apiKey,
  //     }});
  //     const data = await response.json();
  //      csrfToken = data.csrfToken;

  //     // Include the token in subsequent fetch requests
  //     // fetch('/your-api-endpoint', {
  //     //   method: 'POST',
  //     //   headers: {
  //     //     'X-API-Key': apiKey,
  //     //     'X-CSRF-Token': csrfToken 
  //     //   },
  //     //   // ... rest of your fetch options
  //     // });
  //   };

  //   fetchCsrfToken();
  // }, []); 

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [reason, setReason] = useState('');
  const { RegNo, SName, Course } = studentData;
  const [success, setSuccess] = React.useState(true);
  const [ResponseSuccess, setResponseSuccess] = React.useState(false);
  const [errorMsg, seterrorMsg] = React.useState('');
  const [successMsg, setsuccessMsg] = React.useState('');
  const [letterData,setLetterData]=useState({});

  const [isLoading, setIsLoading] = useState(false);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[10],
      },
    }),
  };

  const handleSubmit =  (event) => {
    
    event.preventDefault();
    const totalDays = calculateTotalDays(fromDate, toDate);
   
    async function submitData() {
      setIsLoading(true);
      wait(5000);
      const formatDate = (date) => {
        const formattedDate = new Date(date);
        const year = formattedDate.getFullYear();
        const month = ('0' + (formattedDate.getMonth() + 1)).slice(-2);
        const day = ('0' + formattedDate.getDate()).slice(-2);
        return `${year}/${month}/${day}`;
      };
      const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    
      // Now you can use csrfToken in your subsequent fetch requests:
      fetch(API_URL+'/leave-letters/new', {
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'regno': RegNo,
          'sname': SName,
          'course': getShortForm(Course),
          'fromdate': formattedFromDate,
          'todate': formattedToDate,
          'reason': reason,
          'approved': 'pending',
          'totaldays': totalDays
        })
      })
        .then(response => {
          if (!response.ok) {
            setSuccess(false);
            seterrorMsg('Network Error!')
            setIsLoading(false);
            throw new Error('Network response was not ok. Status: ' + response.status);
          }else
          {
            setSuccess(true);
            setsuccessMsg('Leave Leter Submitted Successfully');
            setResponseSuccess(true);
            setIsLoading(false);

            
          }
         
          return response.json();
        })
        .then(data =>{ 
          
          setLetterData(data.data);
        })
        .catch(error => {
          seterrorMsg('Error!');
          console.error('There has been a problem with your fetch operation:', error);
          setSuccess(false);
        });
    }



     submitData();
     

  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSuccess = () => {
    onSuccess(letterData);
  };

  const handleClose = () => {
    setSuccess(true);
  };
  return (
    <Paper elevation={3} sx={{ maxWidth: 'auto', margin: '0px 10px', padding: '22px', background: 'rgba(255,255,255,0.5)' }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4}>
            <TextField  label="Register Number"  disabled value={RegNo} fullWidth InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Name" value={SName} disabled fullWidth InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField label="Course" disabled value={Course} fullWidth InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item xs={6} sm={6} sx={{textAlign:'left'}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                TextField={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={6} sx={{textAlign:'left'}}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                TextField={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sx={{paddingRight:'12px'}}>
            <TextareaAutosize
              minRows={5}
              placeholder="Reason for Leave"
              style={{ width: '100%'}}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="outlined" onClick={handleCancel}>Cancel</Button>
          </Grid>
          <Grid item xs={6}>
          <Box sx={{ m: 0, position: 'relative' }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Submit
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[800],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
           
          </Grid>
        </Grid>
      </form>
      {!success &&

        <Modal
          open={!success}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={style}>

            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
              <Alert variant="filled" severity="error" sx={{ fontSize: '1rem' }}>{errorMsg}</Alert>
            </Typography>
          </Card>
        </Modal>
      }
      {
        ResponseSuccess&&
        <Modal
          open={ResponseSuccess}
          onClose={handleSuccess}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={style}>

            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
              <Alert variant="filled" severity="success" sx={{ fontSize: '1rem' }}>{successMsg}</Alert>
            </Typography>
          </Card>
        </Modal>
      }
    </Paper>
  );
}

export default LeaveLetterForm;
