import React, { useState, useEffect } from 'react';
import LeaveLetterDataGrid from './LeaveLetterDataGrid';
import { Box } from '@mui/material';
import ResponsiveAppBar from '../LeaveLetter/ResponsiveAppBar';
import LinearProgress from '@mui/material/LinearProgress';
import AdminLoginForm from './AdminLoginForm';


const API_URL = process.env.REACT_APP_API_URL;











async function fetchStudentDetails(regno) {
  try {
      const apiKey = process.env.REACT_APP_API_KEY;
      const apiUrl = `http://127.0.0.1:8000/api/students/byreg/${regno}`;

      const response = await fetch(apiUrl, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          }
      });

      if (!response.ok) {
          throw new Error(`API request failed for student ${regno}`);
      }

      const data = await response.json();
      return data;

  } catch (error) {
      console.error('Error fetching student details:', error);
      // Consider returning null or a placeholder object for error handling
      return null; 
  }
}



function LeaveLetterAdminIndex() {
  const [leaveData, setLeaveData] = useState([]); // Initial data is empty array
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(2);
  const [error, setError] = useState(null);






  async function fetchPendingLeaveLetter() {
    try {
      //return;
      //const API_URL = process.env.REACT_APP_API_URL;
      const apiUrl = API_URL + `/leave-letters`;
  
      const response = await fetch(apiUrl, {
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        }
      });
  
      if (!response.ok) {
        setCurrentStep(1);
        throw new Error('API request failed');
      }
  
      const data = await response.json();
      return data; // Return the data for the component to use
  
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle the error, e.g., return an empty array or set an error state
      return []; 
    }
  }
  
  








  useEffect(  () => {
    if(!currentStep===2)
    {
      return;
    }
    const fetchData = async () => {
      setIsLoading(true); 
      try {
        // const leaveLetters = await fetchPendingLeaveLetter();
        // const updatedLeaveData = await Promise.all(leaveLetters.data.map(async (letter) => {
        //     const studentDetails = await fetchStudentDetails(letter.regno); // Assuming 'regno' is in the leave letter
        //     return { ...letter, studentDetails };
        // }));
        // setLeaveData(updatedLeaveData);
        const data = await fetchPendingLeaveLetter();
        setLeaveData(data.data || []);
      
        
    } catch (error) {
     
        setError(error);
    } finally {
        setIsLoading(false);
    }
    };
     
    fetchData(); 
  }, [currentStep]); // Empty dependency array: Fetch only once on component mount

  const handleLogout = () => {
    setCurrentStep(1); 
  };

  return (
    <Box>
      <ResponsiveAppBar />
      <Box sx={{  height:'100vh',background:'whitesmoke',display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        {currentStep === 1 && <AdminLoginForm onLoginSuccess={()=>{setCurrentStep(2)}} />}
        {currentStep === 2 && isLoading && <LinearProgress color="success" sx={{width:'50%',mt:50}}/>}
        {error && <p>Error fetching data: {error.message}</p>}
        { currentStep === 2 && !isLoading && !error && (
          <LeaveLetterDataGrid rows={leaveData} onSetLogout={handleLogout}/>
        )}
      </Box>
    </Box>
  );
}

export default LeaveLetterAdminIndex; 
