import './features/LeaveLetter/RegisterInput'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LeaveLetterIndex from './features/LeaveLetter/LeaveLetterIndex';
import LeaveLetterAdminIndex from './features/LeaveLetterAdmin/LeaveLetterAdminIndex';
import { LicenseInfo } from '@mui/x-data-grid-pro';

function App() {
  
  LicenseInfo.setLicenseKey(
    'e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y',
  );
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<LeaveLetterIndex />} />
      <Route path="/admin" element={<LeaveLetterAdminIndex />} />
    </Routes>
  </BrowserRouter>

  );
}

export default App;
