import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { alignProperty } from '@mui/material/styles/cssUtils';
import Alert from '@mui/material/Alert';
import Modal from '@mui/material/Modal';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const API_URL = process.env.REACT_APP_API_URL;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid rgba(0, 0, 0, 0.57)',
  boxShadow: 24,
  p: 1,
};




function RegisterInput({ onNextStep }) {

  const [isLoading, setIsLoading] = useState(false);

  const [regNumber, setRegNumber] = useState('');
  const [success, setSuccess] = React.useState(true);
  const [errorMsg, seterrorMsg] = React.useState('');
  const handleClose = () => setSuccess(true);
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const handleRegNumberChange = (event) => {
    setRegNumber(event.target.value);
  };




  
  const handleNextClick = async () => {
    try {
      setIsLoading(true);
      // Assuming a REST API endpoint to fetch data based on register number
      const apiKey = process.env.REACT_APP_API_KEY;
      const apiUrl = API_URL+`/students/byregp/${regNumber}`;

      const response = await fetch(apiUrl, {

        headers: {
          'X-API-Key': apiKey,
        }
      });

      if (!response.ok) {
        //throw new Error('API request failed');
        seterrorMsg('Error!')
        setSuccess(false);
        setIsLoading(false);

      }

      const data = await response.json();
      const { RegNo, Course, SName } = data.data;
      //console.log("Data:",SName);
      // Do something with the fetched data 
      //console.log('Data from API:', data);
      onNextStep(data.data);
      setIsLoading(false);


    } catch (error) {
     // console.error('Error fetching data:', error);
     seterrorMsg('Invalid Register Number! Please Try Again.')
     setSuccess(false);
     setIsLoading(false);
      // Handle the error, e.g., display a message to the user
    }

  };

  return (
    <Paper elevation={3} sx={{ maxWidth: 350, margin: '20px 10px', background:'rgba(255,255,255,0.5)'}}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Enter Register Number
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <TextField
            label="Register Number"
            value={regNumber}
            onChange={handleRegNumberChange}
            variant="outlined"
          />
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={isLoading}
              onClick={handleNextClick}
            >
              <NavigateNextIcon/>
            </Button>
            {isLoading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
          {/* {!success && <Alert variant="filled" severity="error" sx={{fontSize:'13px'}}>{errorMsg}</Alert>} */}
          {!success && 
          
          <Modal
          open={!success}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Card sx={style}>
            
            <Typography id="modal-modal-description" sx={{ mt: 0 }}>
            <Alert variant="filled" severity="error" sx={{fontSize:'1rem'}}>{errorMsg}</Alert>
            </Typography>
          </Card>
        </Modal>
          }
        </Box>
      </CardContent>
    </Paper>
  );
}

export default RegisterInput;
