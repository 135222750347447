import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';






const API_URL = process.env.REACT_APP_API_URL;

function AdminLoginForm({onLoginSuccess}) {



    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        login(email,password);
        
        
        // TODO: Add your API call for login here
    };




    async function login(uname, pword) {
        try {
    
            
            const apiUrl = API_URL + `/login`;
            const response = await fetch(apiUrl, {
                method: 'post', // Assuming you're sending the credentials in the body
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json' // If sending JSON data
                },
                credentials: 'include',
                body: JSON.stringify({ email: uname, password: pword }) // Send the credentials
            });
            console.log(response);
            if (!response.ok) {
                
                throw new Error(`API request failed for student `);
               
    
            }
    
    
            const data = await response.json();
            onLoginSuccess();
            return data;
        
    
        } catch (error) {
            console.error('Error fetching student details:', error);
            // Consider returning null or a placeholder object for error handling
            return null;
        }
    }









    return (
        <Container maxWidth="xs">
            <Box sx={{ mt: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Username"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default AdminLoginForm;
